export default {
 // Produto
  numberErrors () {
    const errors = []
    if (!this.$v.item.number.$dirty) return errors
    !this.$v.item.number.required && errors.push('Número é obrigatório.')
    !this.$v.item.number.numberRegister && errors.push('Não pode repetir número.')
    return errors
  },
  descriptionErrors () {
    const errors = []
    if (!this.$v.item.description.$dirty) return errors
    !this.$v.item.description.required && errors.push('Descrição é obrigatório.')
    return errors
  },

  prizeTypeErrors () {
    const errors = []
    if (!this.$v.item.prizeTypeId.$dirty) return errors
    !this.$v.item.prizeTypeId.required && errors.push('Por favor selecionar o tipo do prêmio.')
    return errors
  },

  valueErrors () {
    const errors = []
    if (!this.$v.item.value.$dirty) return errors
    !this.$v.item.value.requiredNotBingo && errors.push('Por favor digitar um valor de prêmio maior que 0.')
    return errors
  },

  qtdPrizesErrors () {
    const errors = []
    if (!this.$v.item.qtdPrizes.$dirty) return errors
    !this.$v.item.qtdPrizes.required && errors.push('Por favor digitar a quantidade de prêmios.')
    !this.$v.item.qtdPrizes.minValue && errors.push('Por favor digitar um valor maior que 0.')
    return errors
  },
   percentageMaxErrors () {
    const errors = []
    if (!this.$v.item.percentage.$dirty) return errors
    !this.$v.item.percentage.required && errors.push('Por favor digitar a quantidade de prêmios.')
    !this.$v.item.percentage.percentageMax && errors.push('Valor da porcentagem atingiu o valor Máximo 100%')
    // !this.$v.item.qtdPrizes.minValue && errors.push('Por favor digitar um valor maior que 0.')
    return errors
  },
}