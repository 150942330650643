<template>
  <v-form class="box-form box-form-products">
    <!-- <v-card-title class="modal-cadastro-titulo">
      Concurso: {{ sweepstakes.cod }} 
    </v-card-title> -->
    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="selectPrizeType.length > 1">
          <v-select
            :items="selectPrizeType"
            label="Tipo"
            v-model="item.prizeTypeId"
            :error-messages="prizeTypeErrors"
            @input="$v.item.prizeTypeId.$touch()"
            @blur="$v.item.prizeTypeId.$touch()"
          />
        </v-col>
        <!--<v-col cols="12" class="modal-cadastro-form-col-imput" :sm="addCampoTotalPrizes ? '3' : '4'">
           <dateTimerPicker
            label="Data de prêmios"
            v-model="sweepstakes.config.dtDraw"
            :datePickerProps="{locale: $store.getters.Locale, min: dataMinima}"
            :timePickerProps="{format: '24hr', min: horaMinima}"
            date-format="dd/MM/yyyy"
            time-format="HH:mm"
            :key='componentKey'
            clearText="Limpar"
            @valorDate="compararData"
            >
          </dateTimerPicker> 
        </v-col>-->
        <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="addCampoTotalPrizes ? '2' : '3'">
          <v-text-field type="number" :error-messages="numberErrors" v-model.number="item.number" label="Número"  @input="$v.item.number.$touch()" @blur="$v.item.number.$touch()" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
          <v-text-field v-model="item.description" label="Descrição" :error-messages="descriptionErrors" />
        </v-col>

        <!-- Quantidade de premios -->
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="addCampoTotalPrizes">
          <v-text-field :error-messages="qtdPrizesErrors" v-model="item.qtdPrizes" label="Quantidade de prêmios"  @input="$v.item.qtdPrizes.$touch()" @blur="$v.item.qtdPrizes.$touch()" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
          <v-currency-field v-model="sweepstakes.config.accumulation.percentage" label="% Acumulado" prefix="%" disabled />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
          <v-currency-field v-model="sweepstakes.config.cardConfig.expectedSales" label="Previsão de vendas" prefix="R$" disabled />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
          <v-currency-field v-model="sweepstakes.config.cardConfig.payoutPercentage" label="Payout" prefix="%" disabled />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
          <v-currency-field
            v-model="prizePrevisto"
            label="Prêmio previsto"
            prefix="R$"
            :error-messages="valueErrors"
            disabled
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
            <v-currency-field
              v-model="item.value"
              label="Valor do prêmio" prefix="R$"
              :error-messages="valueErrors"
              :disabled="isBingo"
            />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="isBingo">
          <percentage
            v-model.number="item.percentage"
            :suffix="'%'"
            label="Porcentagem"
            :error-messages="percentageMaxErrors"
            @input="$v.item.percentage.$touch()"
            @blur="$v.item.percentage.$touch()"
            :hint="totalFaltantePorcentage"
            persistent-hint
          />
        </v-col>

        <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
          <v-file-input
            label="Imagem"
            prepend-icon="mdi-camera"
            v-model="image"
          ></v-file-input>
        </v-col>  -->
        <v-col cols="12" class="modal-cadastro-form-col-imput">
          <v-btn :color="variables.colorPrimary" @click="cancel" class="br-btn br-btn-cancelar br-btn-entity-products mr-2" :width="70">Limpar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="add" class="br-btn br-btn-entity-products" :loading="loading" :width="70" v-if="hasSweepstake(sweepstakes.id)">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="add" class="br-btn br-btn-entity-products" :loading="loading" :width="70" v-else>{{ item.idArray >= 0 ? 'Salvar' : 'ADD' }}</v-btn>
        </v-col>

      </v-row>
    </v-card-text>
    <v-card-text class="modal-cadastro-form mt-5" v-if="listaItens">
      <v-skeleton-loader
          :loading="loadingSkeleton"
          :transition="'fade-transition'"
          height="160"
          type="table">
        <v-data-table
          :headers="headers"
          :items="arrayItemWithImage"
          width="300px"
          height="auto"
          flat
          :loading="loadingTabela"
          :items-per-page="10"
          dense
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.id"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.prizeTypeId }}</td>
                <td>{{ item.number }}</td>
                <td>{{ item.value | currency }}</td>
                <td>{{ item.qtdPrizes ? item.qtdPrizes : '-' }}</td>
                <td>{{ item.percentage }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <v-img v-if="item.image" :src="item.image" aspect-ratio="2.5"></v-img>
                </td>
                <!-- <td>{{ sweepstakes.config.dtDraw }}</td> -->
                <td>
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, index)"
                  >
                    edit
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                    v-if="isDelectable(item.prizeTypeId)"
                  >
                    delete
                  </v-icon>
                </td>

              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-skeleton-loader>
    </v-card-text>


    <v-card-actions class="modal-cadastro-footer" v-if="!hasSweepstake(sweepstakes.id)">
      <v-btn :color="variables.colorPrimary" @click="$emit('voltar')" class="br-btn br-btn-cancelar">Voltar</v-btn>
      <v-btn :color="variables.colorPrimary" @click="proximo()" class="br-btn" :loading="loadingCadastro">Proximo</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import moment from 'moment'
import { filter, size } from 'lodash'

// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'

import variables from '@/assets/styles/helpers/_variables.scss'

import validacao from './validacao'
// import VCurrencyField from '@/views/components/vCurrencyField'
// import dateTimerPicker from '@/views/components/dateTimePicker'
import percentage from '@/views/components/percentage'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
// const validCNPJ = (params) => {
//   console.log(params)
// }


const paramsNumber = (listaPremios, isEdit, idArray) => value => {
  if (isEdit) return true
  if (idArray >= 0) return true

  return !listaPremios.some(item => item.number === value)
}

// se for diferente de bingo e a descricao for preenchida o valor do premio nao eh obrigatorio
const paramsIsBingo = (isBingo, description) => value => !isBingo && (value <= 0) && !description ? !!0 : !!1

// eslint-disable-next-line
const paramIsPrognostico = (isProg) => {
  return isProg ? false : true
}

const validateMaxPercentage = (testePercentage) => value => testePercentage + value <= 100

export default {
  name: 'ModalCadastroPremiosConcursoRecorrente',
  mixins: [validationMixin],
  directives: {mask},
  props: {
    sweepstakes: {
      type: Object
    },
    valueKey: {
      type: Number
    },
    loadingCadastro: {
      type: Boolean,
      default: false
    }
  },
  components: { percentage },
  data: () => ({
    activeModal: false,
    loading: false,
    search: '',
    headers: [
      { class: 'table-header', text: 'ID', value: 'id' },
      { class: 'table-header', text: 'Tipo de prêmio', value: 'prizeTypeId' },
      { class: 'table-header', text: 'Número', value: 'number' },
      { class: 'table-header', text: 'Valor', value: 'value' },
      { class: 'table-header', text: 'Quantidade de prêmios', value: 'qtdPrizes' },
      { class: 'table-header', text: 'Porcentagem', value: 'percentage' },
      { class: 'table-header', text: 'Descrição', value: 'description' },
      { class: 'table-header', text: 'Imagem', value: 'image' },
      // { class: 'table-header', text: 'Data do prêmio', value: 'dtDraw' },
      { class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    divider: '/',
    desserts: [],
    itemPerPage: [5, 10, 50, 100, {text: 'Todos', value: -1}],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false /* doesn't work with directive */
    },
    form: {
      comission: '',
      debtLimit: ''
    },
    componentKey: 0,
    datetime: '',
    horaMinima: '00:00',
    image: [],
    loadingSkeleton: false,
    arrayItemWithImage: [],
    loadingTabela: false,
    prizePrevisto: 0,
    totalPorcentage: 0,
    show: false
  }),

  watch: {
    'value' (val) {
      this.item.value = val
    },

    'image' (val) {
      this.addImage(val)
    },

    'selectPrizeType' (val) {
      if(size(val) === 1) {
        this.item.prizeTypeId = val[0].value
      } 

      // Arrumar urgente 
      if(size(val) === 0) {
        this.item.prizeTypeId = 'PE'
      } 
    },

    'item.qtdPrizes' (val) {
      this.item.qtdPrizes = String(val).replace(/\D/g,"")
    },
    'item.percentage' (val) {
      if (val && this.prizePrevisto) {
        this.item.value = this.prizePrevisto * val / 100
      }
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('sweepstakesRecurrentPrizesConfig', ['listaItens', 'item', 'listaItensSelect']),
    
    ...validacao,

    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    selectPrizeType () {
      const self = this
      // let returnSelectPrizeType = []
      return filter(this.$store.getters['prizeTypes/listaItensSelect'], item => {
        const result = filter(item.ascertainedMethodTypes, itemFilter => {
          return itemFilter.ascertainedMethodId === self.sweepstakes.ascertainedMethodId
        })
        return size(result)
      })

      // return returnSelectPrizeType
    },

    dataMinima () {
      return moment(this.sweepstakes.dtDraw, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    isBingo () {
      return this.sweepstakes.ascertainedMethodId === 'BINGO' ? !!1 : !!0
    },

    addCampoTotalPrizes () {
      return this.sweepstakes.ascertainedMethodId === 'RASPADINHA' ? !!1 : !!0
    },

    testeTotalPorcentage () {
      const percentageAccumulation = (this.sweepstakes.config &&
                                      this.sweepstakes.config.accumulation &&
                                      this.sweepstakes.config.accumulation.percentage) ? parseFloat(this.sweepstakes.config.accumulation.percentage) : 0

      const totalPorcentageArray = this.arrayItemWithImage.reduce((acc, { percentage }) => acc + parseFloat(percentage), 0)

      return percentageAccumulation + totalPorcentageArray
    },

    totalFaltantePorcentage () {
      return `Falta ${100 - this.testeTotalPorcentage }%`
    }
  },
  mounted () {
    Events.$on('modalCadastroSweepstakes::closeModal', () => {
      this.cancel()
    })

    this.getItensPrizeTypes()
    this.listarItens()

    if (this.sweepstakes.config.cardConfig.expectedSales && this.sweepstakes.config.cardConfig.payoutPercentage) {
      this.prizePrevisto = this.sweepstakes.config.cardConfig.expectedSales * this.sweepstakes.config.cardConfig.payoutPercentage / 100
    }
  },

  methods: {
    ...mapActions('sweepstakesRecurrentPrizesConfig', ['setItem', 'editarItem', 'cadastrarItem', 'limparItemCadastro', 'deletarItem', 'addPremio', 'removePremio']),
    ...mapActions('sweepstakesRecurrentPrizesConfig', { getPrizesConfig: 'obterItens'}),
    ...mapActions('prizeTypes', {
      getItensPrizeTypes: 'getItens'
    }),
    openModal () {
      this.activeModal = true
    },

    addSelectPrizeId (prizeType) {
      if(size(prizeType) === 1) {
        this.item.prizeTypeId = prizeType[0].value
      } 

      // Arrumar urgente 
      if(size(prizeType) === 0) {
        this.item.prizeTypeId = 'PE'
      } 
    },

    cancel () {
      this.forceRender()
      this.limparItemCadastro(this.sweepstakes.dtDraw)
      this.$v.item.$reset()
      this.loading = false
      this.image = null
      this.item.id = null
    },

    async listarItens() {
      if (this.sweepstakes.id) {
        console.log('ceio aqui')
        this.arrayItemWithImage = []
        this.loadingTabela = true
        this.getPrizesConfig({ sweepstakeRecurrenceId: this.sweepstakes.id })
        .then((result) => {
          const data = Object.values(result.data)
          data.pop()
          this.arrayItemWithImage = data
        })
        .catch(err => {
           errorSnackbar(err.error)
        })
        .finally(() => this.loadingTabela = false)
      }
    },

    isDelectable (prizeTypeId) {
      if (!(this.sweepstakes.id >= 0)) return true

      return (prizeTypeId === 'PRL' || prizeTypeId === 'PRCF') ? !!0 : !!1
    },

    hasSweepstake(sweepstake) {
      if (sweepstake === '') return false
      return sweepstake >= 0 ? !!1 : !!0
    },

    forceRender() {
      this.componentKey += 1
    },

    compararData(payload) {
      if (moment(this.sweepstakes.dtDraw, 'DD/MM/YYYY').isSame(payload, 'YYYY-MM-DD')) {
        this.horaMinima = moment(this.sweepstakes.dtDraw, 'DD/MM/YYYY HH:mm').format('HH:mm')
        return 
      }

      this.horaMinima = '00:00'
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    editItem (item, index) {
      this.setItem({ item, index })
    },

    addImage (e) {
      if (!e) {
        this.item.image = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.image = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.image = e.target.result;
      reader.readAsDataURL(e);
    },

    deleteItem (item) {
      if (
        item.id &&
        item.prizeTypeId !== 'PRL' &&
        item.prizeTypeId !== 'PRCF'
      ) {
        this.$swal({
          icon: 'warning',
          text: `Tem certeza que deseja excluir`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.value) {
            this.deletarItem({id: parseInt(item.id)}).then(() =>{
              this.listarItens()
              successSnackbar('Item excluído com sucesso!')
            }).catch(err => {
              errorSnackbar(err.error)
            })
          }
        })
      } else {
        this.removePremio(item)
          .then(result => this.arrayItemWithImage = result)
          .catch(error => errorSnackbar(error))
      }
    },

    add () {
      if (this.$v.item.prizeTypeId.$invalid) {
        errorSnackbar('Tipo invalido ou vazio')
      }

      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }
      let dados = {
        number: parseInt(this.item.number),
        value: this.item.value || 0,
        description: this.item.description || 0,
        imageId: this.item.image ? this.item.image : null,
        prizeTypeId: this.item.prizeTypeId ,
        percentage: this.item.percentage || 0,
        sweepstakeRecurrenceId: this.sweepstakes.id ? parseInt(this.sweepstakes.id) : null,
        interval: 0
      }

      if (this.sweepstakes.id >= 0) { // se tiver o id do concurso é pq esta querendo editar ou cadastrar premios com concurso ja cadatrado
        if (this.item.id) { // se tiver id, pq esta querendo editar um premio ja cadastrado
          dados.id = this.item.id
          this.editarItem(dados).then(() => {
            this.listarItens()
            this.limparItemCadastro()
            successSnackbar('Edição realizado com sucesso!')
          }).catch(err => {
            errorSnackbar(err.error)
          })
        } else { // senao tiver id, pq é um premio novo, porem ja foi cadastrado um concurso
          dados.value = parseInt(this.item.value)
          this.cadastrarItem(dados)
          .then(() => {
            this.listarItens()
            successSnackbar('Prêmio cadastrado com sucesso!')
          }).catch(err => errorSnackbar(err.error))
        }

        this.cancel()

        return
      }
      

      dados.dtDraw = this.item.dtDraw ? moment(this.item.dtDraw,  'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm') : null
      
      if (this.item.idArray >= 0) dados.idArray = this.item.idArray

      if (this.validarAddPremio(this.item) && !(this.item.idArray >= 0))  {
        this.$swal({
          icon: 'error',
          text: `Não é possivel cadastrar mais premio ${this.selectPrizeType.filter(item => item.value === this.item.prizeTypeId)[0].text}`,
          showConfirmButton: false
        })

        return false
      }

      // add premios quando o concurso ainda não foi cadastrado
      this.addPremio(dados)
        .then((item) => {
          this.arrayItemWithImage = item
        })
        .catch(err => errorSnackbar(err.error))
    },

    validarAddPremio (premio) {
      if (['PRL', 'PRT', 'PRC', 'PRCF'].includes(premio.prizeTypeId)) {
        return this.listaItens.filter(item => item.prizeTypeId === premio.prizeTypeId).length
      }
    },

    validarPremios () {
      if (this.sweepstakes.ascertainedMethodId === 'BINGO') {
        //eslint-disable-next-line
        let countedPremio = this.listaItens.reduce((allPremios, premio) => {
          if (premio.prizeTypeId in allPremios) {
            allPremios[premio.prizeTypeId]++
          } else {
            allPremios[premio.prizeTypeId] = 1
          }

          return allPremios
        }, {})

        // validacao de Premio Rateado Linha e Premio Rateado Cartela Fechada
        return countedPremio.PRL = 1 && countedPremio.PRCF === 1 ? !!1 : !!0
      }

      return true
    },

    proximo () {
      if (!this.validarPremios()) {
        this.$swal({
          icon: 'error',
          text: `É obrigatorio o cadastro de 5 Prêmios Rateado Linha e 1 Prêmio Rateado Cartela Fechada!`,
          showConfirmButton: false
        })

        return false
      }

      if (!size(this.listaItens)) {
        this.$v.item.$touch()
        return false
      }

      if (!this.validarPorcentage()) {
        this.$swal({
          icon: 'error',
          text: `É obrigatorio cadastrar 100% de porcentagem`,
          showConfirmButton: false
        })

        return false
      }

      this.$emit('proximo')
    },

    validarPorcentage () {
      return (this.testeTotalPorcentage === 100 || this.sweepstakes.ascertainedMethodId !== 'BINGO')
    },
  },

  validations () {
    return  {
      item: {
        number: { 
          required,
          numberRegister: paramsNumber(this.listaItens, this.isEdit, this.item.idArray)
        },
        value: {
          requiredNotBingo: paramsIsBingo(this.isBingo, this.item.description) // se for diferente de bingo e a descricao for preenchida o valor do premio nao eh obrigatorio
        },
        prizeTypeId: { required },
        description: {
          required: requiredIf(() => this.item.value || this.isBingo <= 0 ? !!1 : !!0) // se o valor do premio for maior que 0, a descricao nao eh obrigatorio
        },
        qtdPrizes: {
          required: requiredIf(() => this.addCampoTotalPrizes), // é obrigatorio quando quando a condicao do tipo de concurso for verdadeiro
          // minValue: minValue(1)
        },
        percentage: {
          required: requiredIf(() => this.isBingo),
          percentageMax: validateMaxPercentage(this.testeTotalPorcentage)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-cadastro-form .theme--light.v-data-table {
    background-color: #FFF;
  }

  .br-btn-entity-products {
    margin-top: 10px;
    min-width: auto !important;
    font-size: 0.8em !important;
  }

  .modal-cadastro-form::v-deep .v-badge__badge {
      top: 0px !important;
      /* right: 21px; */
      left: 80px !important;
  }
</style>