<template>
  <v-text-field 
    ref='field'
    type="tel"
    :suffix='suffix'
    v-model='model'
    @focus='onFocus'
    @keyup='onKeyUp'
    :error-messages='errorMessages'
    v-bind='$attrs'
    @change='onChange'
    @blur='onBlur'
    :label="label"
    class="text-field-percentage"
  />
</template>

<script>
function tryParseFloat (str, defaultValue) {
  var retValue = defaultValue
  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(str)) {
        retValue = parseFloat(str)
      }
    }
  }
  return retValue
}
export default {
  props: {
    value: {
      default: ''
    },
    'error-messages': null,
    allowNegative: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: 'R$ '
    },
    thousandsSeparator: {
      type: String,
      default: '.'
    },
    decimalSeparator: {
      type: String,
      default: ','
    },
    languageCode: {
      type: String,
      default: 'pt-BR'
    },
    label: {
      type: String,
      default: 'Valor'
    }
  },
  data () {
    return {
      numberValue: this.value,
      model: this.value,
      isMasked: true,
      thousandsSeparatorRegex: new RegExp(`\\${this.thousandsSeparator}`, 'g'),
      decimalSeparatorRegex: new RegExp(`\\${this.decimalSeparator}`, 'g')
    }
  },
  methods: {
    onFocus () {
      this.isMasked = false
      this.updateModel()
    },
    onBlur () {
      if (this.numberValue === null) {
        this.numberValue = 0,0
        return
      }
      if (this.$listeners.blur) this.$listeners.blur()
      this.isMasked = true
      this.format()
    },
    onKeyUp () {
      this.updateNumberValue()
    },
    onChange () {
      if (this.$listeners.change) this.$listeners.change()
    },
    updateNumberValue () {
      let v = this.model
      let parsed
      if (!v) return 
      v = v.replace(this.thousandsSeparatorRegex, '')
      if (this.decimalSeparator !== '.') v = v.replace(this.decimalSeparatorRegex, this.thousandsSeparator)
      const result = tryParseFloat(v)
      if (!result) parsed = 0
      else parsed = result
      if (!this.allowNegative && result < 0) parsed = 0
      this.numberValue = Math.round(parsed * 100) / 100
    },
    updateModel () {
      if (this.numberValue === 0) {
        this.model = null
        return
      }
      if (this.numberValue === null) return
      let v = this.numberValue.toString()
      v = v.replace(this.thousandsSeparatorRegex, this.decimalSeparator)
      this.model = v
    },
    format () {
      if (this.numberValue === null) return
      let v = this.numberValue
      v = v.toLocaleString(this.languageCode)
      if (v.length !== 1 && v.slice(v.indexOf(this.decimalSeparator) + 1).length === 1) v += '0'
      this.model = v
    }
  },
  watch: {
    numberValue (v) {
      this.$emit('input', v)
    },
    value (v) {
      this.numberValue = v
      if (!this.$refs.field.isFocused) {
        this.format()
      }
    }
  },
  created () {
    this.format()
  }
}
</script>

<style lang="scss">
  .text-field-percentage {
    input {
      text-align: right !important;
    }
  }
</style>